@media (min-width: 481px) {
    .logo-container {
        position: absolute;
        left: 0;
        margin-right: auto;
        top: 0;
        margin-bottom: auto;
        width: 45%;
        padding: 0;
    }
    .home-logo-container {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 10em;
        width: 40%;
        margin-left: 4em;
    }
    
    .signin-container {
        position: absolute;
        right: 0;
        margin-left: auto;
        top: 0;
        margin-bottom: auto;
        width: 45%;
        text-align: right;
        padding-right: 1.5em;
        margin-right: 10em;
    }

    .about-container {
        margin-top: 10em;
    }

    .login-text {
        color: black !important;
    }

    .slogan {
        margin-top: 1em;
    }

    .explore {
        margin-top: 3em;
    }

    .product-img {
        max-width: 60%;
        width: 60%;
        left: 0;
    }
    
    .product-description {
        font-size: 2em;
        text-align: left;
        font-weight: 200;
        color: white;
        max-width: 80%;
        margin-left: 4em;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 5em;
        position: absolute;
    }

    .home-img-container {
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        width: 100%;
    }

    .home-img {
        display: block;
        width: 100%;
    }

    .product-headline {
        font-size: 2.3rem;
        line-height: 2.5rem;
        text-align: left;
        font-weight: 300;
        color: white;
        margin-top: 1em;
    }
}





@media (max-width: 480px) {
    .logo-container {
        // position: relative;
        // width: 100%;
        // top: auto;
        padding-top: 0.7em;
        padding-bottom: 0.7em;
    }
    .login-logo { 
        max-width: 30%;
        left: 0;
        position: absolute;
    }

    .about-container {
        margin-top: 2em;
    }

    .signin-container {
        position: absolute;
        right: 0;
        margin-left: auto;
        top: 0;
        margin-bottom: auto;
        width: 100%;
        text-align: right;
        padding-right: 0.5em;
        margin-right: 0.5em;
    }

    .header-img-container {
        margin-top: 3.5em;
        text-align: center;
    }

    .login-text {
        color: white !important;
    }

    .home-logo-container {
        max-width: 80%;
        margin-top: 0.5em;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
    }

    .home-img-container {
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        height: 20em;
        display: flex;
        justify-content: center;
    }

    .home-img {
        height: 20em;
        height: 100%;
    }

    .slogan {
        margin-top: 0.5em;
    }
    
    .explore {
        margin-top: 0.5em;
    }

    .product-img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 1em;
        margin-left: 1em;
    }
    
    .product-description {
        font-size: 1.5em;
        text-align: center;
        font-weight: 200;
        color: white;
        max-width: 80%;
        margin-left: 2em;
    }

    .product-headline {
        font-size: 1.3em;
        line-height: 1.5em;
        text-align: left;
        font-weight: 300;
        color: white;
        margin-left: 0;
        margin-top: 0 !important;
        margin-bottom: 0.5rem;
    }

}

.home-arrow-right,
.home-arrow-left {
    position: absolute;
    cursor: pointer;
    max-width: 3em;
    // padding: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    pointer-events: all;
    filter: brightness(100%) drop-shadow(20px 20px 20px #666666);
    opacity: 70%;
    -webkit-filter: brightness(100%) drop-shadow(10px 10px 10px #666666);
}

.home-arrow-container {
  position: absolute;
}
.home-arrow-right {
    right: 0;
    margin-right: auto;
}

.home-arrow-left {
    transform: rotate(180deg);
    left: 0;
}

.slide-show-img {
    max-width: 85%;
}

.home-page-background {
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,0) 100%);
}

.home-page-background-img {
    background-image: url('../images/home-page-background.png');
    width: 100%;
    background-size: cover;
    overflow-y: auto;
    max-width: 100vw;
    background-position: center center;
}

.slogan {
    font-family: "Ubuntu", sans-serif !important;
}
