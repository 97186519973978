$primary: #15084D;

.geo-text {
    font-size: .7em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: dimgray;
}

.item-img-container {
    padding-top: 20%;
    height: 0;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.item-img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.item-container {
  position: absolute;
}
.rating {
    display: inline-block;
    unicode-bidi: bidi-override;
    color: #BEC6D6;
    font-size: 0.5em;
    width: auto;
    position: relative;
    padding: 0;
    margin: 0;
    height: auto;
}

.rating-fill {
    color: $primary;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 0.8;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.rating-overlay {
    padding: 0;
    display: inline-block;
    color: #BEC6D6;
    z-index: 0;
    margin: 0;
}

.filter-white {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    fill: white;
}

.bookmark-dropdown-menu.show {
    transform: translate3d(0.2rem, 1.5rem, 0px) !important;
}

.filter-gray {
    filter: invert(44%) sepia(25%) saturate(0%) hue-rotate(246deg) brightness(89%) contrast(97%);
    fill: dimgray;
}

.bookmark-dropdown-item:hover {
    background-color: lightgray;
}

.bookmark-dropdown-item:active {
    background-color: lightgray;
}

@media (max-width: 480px) {
    .affiliate-link {
        max-width: 18em !important;
    }
}

.loading {
    top: 50%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 20%;
    // right: 0;
    // left: 0;
    // margin-right: auto;
    // margin-left: auto;
}

.right-arrow,
.left-arrow {
    position: absolute;
    cursor: pointer;
    // padding: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    pointer-events: all;
    background-color: black;
    border-radius: 50%;
    opacity: 0.7;

}

.right-arrow {
    right: 0;
    margin-right: auto;
}

.left-arrow {
    left: 0;
}


  


