
$primary: #15084D;
.location-text {
    font-size: 0.9em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    color: dimgray;
}

.feed-img-container {
    padding-top: 138%;
    height: 0;
    width: 100%;
    // overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-radius: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

// .feed-img-container {
//     display: flex;
//     width: 100%;
//     height: 300px;
//     overflow: hidden;
//     position: relative;
//     align-items: center;
//     justify-content: center;
// }

// .feed-image-container {
//     padding-top: 110%;
//     height: 0;
//     width: 100%;
//     overflow: hidden;
//     position: relative;
//     align-items: center;
//     justify-content: center;
//   }
  
  .feed-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
  }

.location-icon {
    height: 1.5em;
    //filter: invert(42%) sepia(0%) saturate(2037%) hue-rotate(317deg) brightness(93%) contrast(84%);
    filter: invert(15%) sepia(13%) saturate(6618%) hue-rotate(228deg) brightness(83%) contrast(122%);
}

.show-details:hover {
    text-decoration: underline;
}
// .feed-profile-pic-container {
//     width: 70%;
// }
.feed-profile-pic-container {
    padding-bottom: 64%;
    height: 0;
    width: 70%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    position: relative;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: gray;
}

.profile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 480px) {
    .feed-profile-pic-container {
        padding-bottom: 64%;
    }
}
// .feed-profile-pic {
//     border-radius: 50%;
//     border: 2px solid $primary;
//     width: 70%;
//     height: 70%;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     position: absolute;
//     cursor: pointer;
//     overflow: hidden;
// }
@media (min-width: 480px) {
    // .feed-container {
    //     width: 75%;
    //     right: 0;
    //     left: 0;
    //     margin-right: auto;
    //     margin-left: auto;
    // }
    .feed-item {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media (max-width: 480px) {
    // .feed-img-container {
    //     padding-top: 120%;
    // }
    
    // .feed-container {
    //     max-width: 100%;
    //     width: 100%;
    //     margin: 0;
    //     padding: 0;
    // }
}