.current-trip-background-img {
    width: 100%;
    background-size: cover;
    overflow-y: auto;
    max-width: 100%;
    background-position: center center;
    min-height: 75vh;
}

.current-trip-heading {
    text-shadow: black 1px 0 20px;
    color: white;
    font-family: "Ubuntu", sans-serif !important;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);

}

.trip-heading {
    text-shadow: black 1px 0 20px;
    color: white;
    font-family: "Ubuntu", sans-serif !important;
}

@media  (max-width: 480px) {
    .current-trip-background-img {
        display: none;
        width: 0%;
        height: 0%;
        padding: 0;
        margin: 0;
    }

    .current-trip-background-button {
        display: none;
        width: 0%;
        height: 0%;
        padding: 0;
        margin: 0;
    }
}

.trip-cover-img-bg {
    height: 0;
    padding-top: 50%;
    background-size: cover;
    background-position: center center;
    background-color: dimgray;
    overflow: hidden;
    position: relative;
}

.trip-cover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
}