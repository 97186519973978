// .modal-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1040;
//     width: 100%;
//     height: 100%;
//     background-color: #000;
//     opacity: .5;
//   }
  
//   .modal-wrapper {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1050;
//     width: 100%;
//     height: 100%;
//     overflow-x: hidden;
//     overflow-y: auto;
//     outline: 0;
//   }
  
//   .modal {
//     z-index: 100;
//     background: white;
//     position: relative;
//     margin: 1.75rem auto;
//     border-radius: 3px;
//     max-width: 500px;
//     padding: 2rem;
//   }
  
.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  cursor: pointer;
  border: none;
}

button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #247BA0;
  color: #fff;
}

.button-delete {
  background: red;
  color: #fff;
}

.img-modal-container {
  padding-top: 75%;
  height: 0;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-color: gray;
}

.img-modal {
  width: 100%;
  height: 100%;
  z-index: 1;
}

// .modal-close {
//   z-index: 2;
//   margin-right: 2rem;
//   margin-top: 2rem;
// }

.modal-details {
  font-size: 0.9em;
  font-weight: 300;
  color: dimgray;
};

.arrow-right,
.arrow-left {
    position: absolute;
    cursor: pointer;
    max-width: 3em;
    // padding: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    pointer-events: all;
    filter: brightness(50%) invert(1) drop-shadow(20px 20px 20px #666666);
    opacity: 70%;
    -webkit-filter: brightness(50%) invert(1) drop-shadow(10px 10px 10px #666666);

}
.arrow-container {
  position: absolute;
}
.arrow-right {
    right: 0;
    margin-right: auto;
}

.arrow-left {
    transform: rotate(180deg);
    left: 0;
}

.modal-dropdown {
  z-index: 1060 !important;
}

.modal-close {
  float: right;
  height: 0.4em;
  width: 0.4em;
  background-color: white !important;
  font-size: 100px;
  font-weight: 700;
  color: black;
  text-shadow: 0 0 15px #2b2b2b;
  filter: alpha(opacity=20);
  opacity: 0.8 !important;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.close-x {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.wide-modal {
  width: 95vw !important;
  max-width: 95vw !important;
  text-align: center !important;
}

