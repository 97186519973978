$primary: #15084D;
// $primary: #06439e;
// $secondary: #164E9F;
$secondary: #06439e;


$theme-colors: (
    primary: $primary,
    secondary: $secondary
);

$open-sans: 'Open Sans', sans-serif;
$font-family-sans-serif: "Lato", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
$enable-responsive-font-sizes: true;
$font-size-base: 1rem;
$enable-shadows: true;

.lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  

.h1, h2 {
    font-family: "Ubuntu", sans-serif !important;
}

.h1, h1 {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Ubuntu", sans-serif !important;
}

.heading-font {
    font-family: "Ubuntu", sans-serif !important;
}


.nav-logo {
    height: 2.3em;
    // padding-left: 0.3rem;
}

.nav-profile-link {
    height: 2em;
    filter: invert(38%) sepia(9%) saturate(19%) hue-rotate(359deg) brightness(101%) contrast(82%);

}

.nav-suitcase-icon {
    height: 3em;
    filter: invert(9%) sepia(90%) saturate(2309%) hue-rotate(226deg) brightness(82%) contrast(105%);
    bottom: 0;


}

.nav-icon {
    width: 2em;
    // filter: invert(10%) sepia(50%) saturate(3607%) hue-rotate(242deg) brightness(84%) contrast(114%);
    filter: invert(14%) sepia(32%) saturate(0%) hue-rotate(202deg) brightness(87%) contrast(86%);
}

.nav-profile-img {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
}

.nav-compass-icon {
    height: 2.3em;
    filter: invert(9%) sepia(90%) saturate(2309%) hue-rotate(226deg) brightness(82%) contrast(105%);
    bottom: 0;
    margin-top: auto;
    top: 0;
    margin-bottom: auto;
    

}

.nav-text {
    color: black;
    font-size: 0.9em;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

$headings-color: $primary;

// .body {
//     min-height: 100vh;
//     display: flex;
//     flex-direction: column;
// }

body {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
  }

.main {
    text-align: center;
    padding: 0px;
    margin: 0;
    width: 100%;
    padding: 0;
    height: 90%;
    max-width: 100%;
}


.footer-text {
    font-size: 0.7em;
    color: $primary;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}



.close-two {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: black;
    float: right;
    z-index: 1;
}

.close {
  float: right;
  height: 40px;
  width: 40px;
  background-color: white !important;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-shadow: 0 0 15px #2b2b2b;
  filter: alpha(opacity=20);
  opacity: 0.8 !important;
  border-radius: 50%;
  margin-top: 1rem;
  margin-right: 1rem;
}



.close:hover{
    color: dimgray;
    filter: alpha(opacity=20);
    opacity: .3;
}

.body-text {
    font-size: 0.8em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: dimgray;
}

.text-gray {
    color: dimgray;
}

// .search-bar input {
//     font-size: 0.8em;
//     font-family: 'Open Sans', sans-serif;
//     font-weight: 300;
//     color: gray;

// }

.search-bar {
    color: black;
}

.rainbow-input input, input:focus, input:active {
    background-color: #f8f9fa !important;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    color: black !important;
}

.rainbow-input input:focus {
    border: 2px solid#15084D !important;
    background-color: #f8f9fa !important;

}

.rainbow-input input:active {
    border: 2px solid#15084D !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    
}

// $enable-caret: false;

.disable-caret::after {
    display: none !important;
}

.DateRangePicker_picker {
    z-index: 6;
}

.filter-primary {
    filter: invert(10%) sepia(50%) saturate(3607%) hue-rotate(242deg) brightness(84%) contrast(114%);
}

.btn-white {
    background-color: white !important;
    border: white !important;
    box-shadow: none !important;
}

.filter-danger {
    filter: invert(32%) sepia(92%) saturate(2193%) hue-rotate(333deg) brightness(87%) contrast(98%);
}

.filter-secondary {
    filter: invert(11%) sepia(90%) saturate(4728%) hue-rotate(211deg) brightness(90%) contrast(95%);
}

.filter-light-blue {
    filter: invert(49%) sepia(65%) saturate(3288%) hue-rotate(164deg) brightness(99%) contrast(103%);
}

.filter-pink {
    filter: invert(51%) sepia(74%) saturate(3631%) hue-rotate(309deg) brightness(90%) contrast(91%);
}

.btn-transparent {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.text-white {
    color: white;
}

.text-black {
    color: black !important;
}

.text-link:hover {
    text-decoration: underline;
}

.notification-number-container {
    position: absolute;
    border-radius: 50%;
    width: 1.2rem;
    background-color: red;
    height: 1.2rem;
    border: 1px solid white;
    top: 0;
    right: 0;
    margin-right: -0.2rem;
}

.notification-number {
    margin-top: -0.015rem;
    font-size: 0.7rem;
}

@media (min-width: 481px) {
    .desktop-hide {
        display: none !important;
    }

    .mobile-header {
        display: none !important;
    }

}

.main-nav {
    position: sticky !important;
    top: 0 !important;
    width: 100% !important;
    background-color: white !important;
    z-index: 1020 !important;
    padding: 0;
}

@media (max-width:480px) {
    .mobile-hide {
        display: none !important;
    }
    .main-nav {
        position: fixed !important;
        left: 0 !important;
        bottom: 0 !important;
        top: auto !important;
        width: 100% !important;
        padding: .2em 0 .2em 0;
    }

    .mobile-header {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1020;
    }
    
    .footer {
        display: none !important;
    }

    .mobile-padding {
        padding-bottom: 4em !important;
    }

    .zero-mobile-padding {
        padding: 0 !important;
    }
}

.link-text:hover {
    text-decoration: underline;
}

.full-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-height-modal {
  height: 97vh; /* Full viewport height */
  margin: 0; /* Remove default margins */
  display: flex; /* Enable flexbox for stretching */
}

.full-height-modal .modal-content {
  flex-grow: 1; /* Allow modal content to stretch */
  display: flex;
  flex-direction: column; /* Stack header, body, and footer */
  height: 100%; /* Ensure full height */
}

.full-height-modal .modal-body {
  flex-grow: 1; /* Allow the body to expand as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push content to the top and form to the bottom */
}

.comment-form-container {
  margin-top: auto; /* Push the form to the bottom of the body */
}
  


// @import "../../node_modules/bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap";