.profile-background-img-container {
    max-width: 100%;
    background-color: gray;
    width: 100%;
    height: 0;
    padding-top: 30%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
}

.profile-background-img {
    display: block;
    width: 100%;
    height: auto !important;
    zoom: 1;
    opacity: 0;
    max-width: 100%;
}

.profile-pic-img-container {
    background-color: gray;
    height: 0;
    padding-top: 95%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    border: 3px solid white;
    margin-top: -10rem;
}

@media (max-width: 480px) {
    .profile-pic-img-container {
        margin-top: -3rem;
    }
}

.profile-pic-img {
    display: block;
    width: 100%;
    height: auto !important;
    zoom: 1;
    opacity: 0;
    max-width: 100%;
}

.follower-container {
    display: flex;
    flex-direction: row;
}

.btn-following::-ms-expand {
    display: block !important;
}

.upload-camera-container {
    // style={{ backgroundColor: "black", opacity: "0.5", borderRadius: "50%" }}
    background-color: black;
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}