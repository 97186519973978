@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import './auth.scss';
@import './modal.scss';
@import './items.scss';
@import './custom.scss';
@import './home-page.scss';
@import './feed.scss';
@import './search.scss';
@import './trips.scss';
@import './profile.scss';







